@import '@we-make-websites/styles-lib/src/config/configuration';
.languageSwitcher {
  &__button {
    @include button-reset;
    padding: 0;
    margin-bottom: var(--spacing-s);

    svg {
      width: 30px;
      height: 20px;
    }
  }
}
