@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Footer Navigation
 * -----------------------------------------------------------------------------
 *
 */
.footerNavigation {
  $parent: &;

  &__desktop,
  &__mobile {
    $parent: &;
    display: grid;
    grid-auto-flow: row;
  }

  &__level1 {
    display: grid;
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-xs);
    height: 100%;

    hr {
      background: var(--color-border-light);
      border: none;
      height: 1px;
      margin: 0;
      width: 100%;
    }
  }

  &__heading {
    color: var(--color-secondary);
    border: none;
    background: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__heading[aria-expanded='true'] &__headingArrow {
    transform: rotate(180deg);
  }

  &__list {
    display: grid;
    gap: var(--spacing-xs);

    a {
      color: var(--color-text-primary);
    }
  }

  &__listItem:nth-of-type(1) {
    padding-block-start: var(--spacing-m);
  }

  &__list &__sale {
    color: var(--color-sale);
  }

  &__desktop {
    display: none;
  }

  &__mobile {
    border-bottom: 1px solid black;
  }

  &__menuItemMobile {
    border-top: 1px solid black;
  }

  @include mq($from: l) {
    grid-auto-flow: column;

    &__listItem {
      a:hover {
        text-decoration: underline;
      }
    }

    &__level1 {
      padding: 0;
    }

    &__level1 hr {
      display: none;
    }

    &__desktop {
      display: flex;
      column-gap: var(--spacing-6xl);
    }

    &__mobile {
      display: none;
    }
  }
}
