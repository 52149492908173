@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Footer Newsletter
 * -----------------------------------------------------------------------------
 * Base styles for footer newsletter.
 *
 */
.newsletter {
  $parent: &;

  &__title {
    padding-block-end: var(--spacing-s);
  }

  &__message,
  &__body {
    padding-block-end: var(--spacing-m);
  }

  &__body {
    display: grid;
    gap: var(--spacing-l);

    label,
    p {
      color: var(--color-secondary);
    }
  }

  &__inputContainer {
    display: grid;
  }

  &__radioContainer {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
  }

  &__errors {
    color: var(--color-support-error) !important;
  }

  &__success {
    margin: var(--spacing-xs) 0;
  }

  &__label__error {
    color: var(--color-support-error) !important;
  }

  @include mq($until: m) {
    &__radioContainer {
      flex-direction: column;
      gap: var(--spacing-xs);
    }

    :global(.form-group__label) {
      line-height: 26px;
    }
  }
}
