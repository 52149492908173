@import '@we-make-websites/styles-lib/src/config/configuration';
/*! purgecss start ignore */
.button {
  @include button-reset;
  @include text-utility-utility-mobile;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;

  :global(.icon) {
    height: var(--icon-s);
    width: var(--icon-s);
  }

  &.icon--left {
    :global(.icon) {
      margin-inline-end: var(--spacing-2xs);
    }
  }

  &.icon--right {
    :global(.icon) {
      margin-inline-start: var(--spacing-2xs);
    }
  }

  &:not(.button--text) {
    color: var(--color-text-light);
    background: var(--color-background-dark);
    border-radius: 80px;
    min-height: 40px;
    padding: var(--spacing-s) var(--spacing-l);
  }

  &#{&}--white {
    background: var(--color-background-light);
    color: var(--color-text-dark);
  }

  &#{&}--outlined {
    background: transparent;
    border: 1px solid var(--color-light-grey);
    color: var(--color-text-primary);
  }

  &#{&}--outlined#{&}--white {
    background-color: var(--color-background-light);
    border-color: var(--color-light-mid-grey);
  }

  &#{&}--featured {
    @include text-utility-utility-small-mobile;
    padding: var(--spacing-xs) var(--spacing-s);

    :global(.icon) {
      height: var(--icon-xs);
      width: var(--icon-xs);
    }
  }

  /**
   * Disabled states
   */
  &#{&}:disabled:not(.button--text),
  &#{&}:global(.is-disabled):not(.button--text) {
    border-color: transparent;
    background-color: var(--color-light-grey);
    color: var(--color-text-dark);
    cursor: default;
  }

  &#{&}:global(.is-disabled) {
    pointer-events: none;
  }

  /**
   * General Modifiers.
   */
  &#{&}--mini {
    @include text-utility-utility-small-mobile;
    &:not(.button--text) {
      min-height: 34px;
      padding: var(--spacing-2xs) calc(var(--spacing-m) + var(--spacing-2xs));
    }
  }

  &#{&}--block {
    display: flex;
    width: 100%;
  }

  &#{&}#{&}--text {
    background: none;
    text-decoration: underline;
    text-underline-offset: var(--spacing-2xs);
  }

  /**
    * Remove text decoration on text links with icons.
    */
  &#{&}--text.icon--left,
  &#{&}--text.icon--right {
    text-decoration: none;
  }

  @include mq($until: m) {
    /**
     * Mini mobile modifier.
     * - Used to apply `mini` modifier on mobile breakpoints only.
     */
    &#{&}--mini-mobile {
      @include text-utility-utility-small-mobile;
      min-height: 34px;
      padding: var(--spacing-2xs) calc(var(--spacing-m) + var(--spacing-2xs));
    }
  }

  @include mq($from: m) {
    @include text-utility-utility;

    // Calc used on to fix vertical alignment. As per designs.
    :global(.icon) {
      margin-block-start: calc(var(--spacing-4xs) * -1);
    }

    &:not(.button--text) {
      min-height: 44px;
      // Calc used on bottom padding to fix vertical alignment. As per designs.
      padding: calc(var(--spacing-s) - var(--spacing-3xs)) var(--spacing-l)
        calc(var(--spacing-xs) + var(--spacing-4xs));
    }

    &#{&}--featured {
      @include text-utility-utility;
      padding: var(--spacing-4xs) calc(var(--spacing-m) + var(--spacing-2xs)) 0
        var(--spacing-m);
    }

    &#{&}--mini {
      @include text-utility-utility-small;

      &:not(.button--text) {
        min-height: 40px;
        // Calc used on top padding to fix vertical alignment. As per designs.
        padding: var(--spacing-4xs) calc(var(--spacing-m) + var(--spacing-2xs))
          0;
      }
    }

    &#{&}--mobileOnly#{&}--mini-mobile {
      @include text-utility-utility-small-mobile;
      min-height: 34px;
      padding: var(--spacing-2xs) calc(var(--spacing-m) + var(--spacing-2xs));
    }

    &#{&}--mobileOnly#{&}--block {
      display: flex;
      width: 100%;
    }

    &#{&}--mobileOnly#{&}--white {
      color: var(--color-text-dark);
    }

    &#{&}--mobileOnly#{&}--outlined {
      background: transparent;
      border: 1px solid var(--color-light-grey);
      color: var(--color-text-primary);
    }

    &#{&}--mobileOnly#{&}--outlined#{&}--white {
      border-color: var(--color-light-mid-grey);
    }

    &#{&}--mobileOnly#{&}--featured {
      @include text-utility-utility-small-mobile;
      padding: var(--spacing-xs) var(--spacing-s);

      :global(.icon) {
        height: var(--icon-xs);
        width: var(--icon-xs);
      }
    }

    &#{&}--mobileOnly#{&}--mini {
      @include text-utility-utility-small-mobile;
      min-height: 34px;
      padding: var(--spacing-2xs) calc(var(--spacing-m) + var(--spacing-2xs));
    }
  }
}
/*! purgecss end ignore */
