@import '@we-make-websites/styles-lib/src/config/configuration';
.siteFooter {
  $parent: &;
  border-top: 1px solid var(--color-border-dark);
  row-gap: var(--spacing-3xl);

  &__navigationWrapper {
    height: 100%;
  }

  &__newsletterWrapper {
    height: 100%;
  }

  &__cookieSettings {
    padding-block-end: var(--spacing-s);

    &Button {
      @include button-reset($font: false);
    }
  }

  &__footerContentWrapper {
    margin-left: var(--margin-desktop);
    margin-right: var(--margin-desktop);
    padding-top: var(--spacing-3xl);
  }

  &__leftContainer,
  &__rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    row-gap: calc(var(--spacing-3xl) + var(--spacing-3xs));
  }

  &__logoWrapper svg {
    width: 100%;
  }

  &__footerImage {
    width: 100%;
    height: 85%;
    object-fit: cover;
  }

  &__componentsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    padding-top: var(--spacing-l);
    row-gap: var(--spacing-xl);
  }

  &__languageSwitcher {
    @include button-reset;
    padding: 0;
  }

  &__levelAccessIcon {
    width: 90px;
    display: block;
  }

  &__levelAccessWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--margin-mobile);
  }

  @include mq($until: l) {
    &__mobileInvisible#{$parent}__desktop {
      display: none;
    }

    &__newsletterWrapper,
    &__navigationWrapper,
    &__socialLinksWrapper,
    &__levelAccessIcon,
    &__languageSwitchWrapper {
      margin-left: var(--margin-mobile);
      margin-right: var(--margin-mobile);
    }
  }

  @include mq($from: l) {
    &__mobileInvisible#{$parent}__desktop {
      row-gap: var(--spacing-3xl);
    }

    &__mobile {
      display: none;
    }
  }
}
